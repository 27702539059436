import { ApplicationRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as AWS from 'aws-sdk';
import { SearchService } from './common-services/search.service';
import { DataService } from './common-services/data.service';
import { Router } from '@angular/router';
import { GlobalVariableService } from './common-services/global-variable.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { IdleTimeComponent } from './modal-popup/idle-time/idle-time.component';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SessionOutComponent } from './modal-popup/session-out/session-out.component';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public rootScope: any;
  @ViewChild('sessionDialog', { static: true }) sessionDialog: TemplateRef<any>;
  @ViewChild('idleDialog', { static: true }) idleDialog: TemplateRef<any>;

  constructor(
    private searchService: SearchService,
    private dataService: DataService,
    private router: Router,
    private globalService: GlobalVariableService,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private idle: Idle,
    private keepalive: Keepalive,
    private appRef: ApplicationRef
  ) {
    // sets an idle timeout of 15 minutes (in seconds).
    this.idle.setIdle(900);

    // sets a timeout period of 5 minute. So after total 20 minutes of inactivity, the user will be considered timed out.
    this.idle.setTimeout(300);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.initIdleTracking();
    this.reset();
  }

  public SS0: any;
  public userData: any;
  public isUserAdmin: any;
  public isLoaded: boolean = false;
  public countdown: any;
  public showPopup: boolean = false;
  public idleDialogRef: any;
  public expireDate: any;
  public hideTabs: boolean = false;
  public loader: boolean = false;

  //idle session related
  public idleState = 'Not started.';
  public timedOut = false;
  public sessionDialogRef: any;
  public lastPing?: Date;

  ngOnInit(): void {
    var expiry =
      localStorage['expireTime'] !== null ? localStorage['expireTime'] : null;
    var jsonToken =
      localStorage['tokenJson'] !== (null || undefined)
        ? localStorage['tokenJson']
        : null;
    var userInfo =
      localStorage['user_sso'] !== (null || undefined)
        ? localStorage['user_sso']
        : null;

    sessionStorage['userArr'] = userInfo;
    sessionStorage['codes'] = jsonToken;

    this.loader = true; // showing main loader
    if (sessionStorage['userArr'] && sessionStorage['userArr'] != 'null') {
      // When user is already logged in
      console.log('USER SESSION FOUND');
      this.getAwsCredentialsAndLoadApp(window.location.pathname);
    } else if (
      !sessionStorage['userArr'] ||
      sessionStorage['userArr'] == 'null'
    ) {
      // Fresh login request
      console.log('USER SESSION NOT PRESENT');
      let tokenArr: any = [];
      tokenArr = window.location.hash.replace('#/', '').split('&');
      let tokenJson: any = {};
      for (let i = 0; i < tokenArr.length; i++) {
        let arr = tokenArr[i].split('=');
        tokenJson[arr[0]] = arr[1];
      }

      // handeling originally requested direct url before SSO Auth
      let origninalReqURL: any = '/';
      if (tokenJson.state) {
        origninalReqURL = decodeURIComponent(tokenJson.state);
      }

      if (tokenJson['#access_token']) {
        this.searchService.login(tokenJson['#access_token']).subscribe(
          (response: any) => {
            sessionStorage['userArr'] = response['custom:GE-SSO'];
            sessionStorage['codes'] = JSON.stringify(tokenJson);
            this.globalService.loginUserDetails.sso = response['custom:GE-SSO'];

            localStorage['tokenJson'] = JSON.stringify(tokenJson);
            localStorage['user_sso'] = sessionStorage['userArr'];

            //Redirect to a clean URL without the hash
            window.history.replaceState(null, '', window.location.pathname); //Changes for Security vulnerability(Improper Session management)

            //window.location.href = origninalReqURL;
            this.getAwsCredentialsAndLoadApp(origninalReqURL);
          },
          (error: any) => {
            console.error('Failed to login through SSO', error);
          }
        );
      } else {
        console.log('User not authenicated, sending for SSO authenication...');
        this.sendForSSOAuth();
      }
    } else {
      this.router.navigate(['/businessLevel/companyRollups']);
    }
  }

  sendForSSOAuth() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href =
      `${environment.cognitoRedirectUri}/oauth2/authorize?identity_provider=${environment.cognitoIdentityProvider}&redirect_uri=` +
      location.origin +
      '&state=' +
      window.location.pathname +
      `&response_type=token&client_id=${environment.cognitoClientId}&scope=aws.cognito.signin.user.admin+openid+profile`;
  }

  getAwsCredentialsAndLoadApp(origninalReqURL: any) {
    AWS.config.region = environment.awsRegion;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.cognitoIdentityPoolId,
      Logins: {
        // user pool id
        [`cognito-idp.${environment.awsRegion}.amazonaws.com/${environment.cognitoUserPoolId}`]: JSON.parse(
          sessionStorage['codes']
        ).id_token,
      },
    });

    let finalURL = origninalReqURL;
    if (origninalReqURL === '/') {
      finalURL = 'businessLevel/companyRollups';
    }

    AWS.config.getCredentials((err) => {
      if (err) {
        console.log('Error getting aws credentials. \n', err);
        console.log('Redirecting user to login page...');
        this.sendForSSOAuth();
      } else {
        localStorage['expireTime'] = (
          AWS.config.credentials as AWS.Credentials
        ).expireTime;
        this.expireDate = localStorage['expireTime'];

        this.dataService.getUser(sessionStorage['userArr']).subscribe(
          (user: any) => {
            if (user.id) {
              this.globalService.loginUserDetails.userData.push(user);
              this.globalService.loginUserDetails.sso = user.sso;
              this.globalService.loginUserDetails.userId = user.id;
              this.globalService.loginUserDetails.userName = user.username;
              this.globalService.loginUserDetails.isAdmin = user.isSiteAdmin;
              this.globalService.loginUserDetails.roleData = user.userRoles;
              this.globalService.industryDetails = user.industryDetails;
              localStorage['isAdmin'] = user.isSiteAdmin;
              this.isUserAdmin = user.isSiteAdmin;

              this.isLoaded = true;
              this.loader = false;
              this.router.navigate([finalURL]);
            } else {
              this.isLoaded = true;
              this.hideTabs = true;
              this.loader = false;
              this.router.navigate(['/unauthorized']);
            }
          },
          (error: any) => {
            console.error(error);
          }
        );
      }
    });
  }

  // Idle session tracking code below
  reset() {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  initIdleTracking() {
    this.idle.onIdleEnd.subscribe(() => {
      this.idleDialogRef.close();
      this.idleState = 'No longer idle.';
      this.timedOut = false;
      this.appRef.tick();
      this.showingTimout();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.idleDialogRef.close();
      this.reset();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      this.idle.clearInterrupts();
      if (!this.globalService.isIdleSession) this.showingIdlePopup();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      const minutes: number = Math.floor(countdown / 60);
      let format =
        ('00' + minutes).slice(-2) +
        ':' +
        ('00' + Math.floor(countdown - minutes * 60)).slice(-2);
      this.idleState = `${format}`;
      this.idleDialogRef.componentInstance.countDown =
        this.idleState != null || this.idleState != undefined
          ? this.idleState
          : this.idleState;
    });
    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  showingIdlePopup() {
    this.idleDialogRef = this.dialog.open(IdleTimeComponent, {
      data: this.countdown,
      panelClass: 'ideldialog',
      disableClose: false,
      autoFocus: false,
    });
    this.idleDialogRef.afterClosed().subscribe((res: any) => {
      this.idle.stop();
      this.reset();
    });
  }

  showingTimout() {
    var today = new Date() as any;
    var expiry: any;
    today = this.datepipe.transform(today, 'dd-MM-yyyy HH:mm');
    expiry = this.datepipe.transform(this.expireDate, 'dd-MM-yyyy HH:mm');
    if (today < expiry) {
      this.idle.stop();
      this.sessionDialogRef = this.dialog.open(SessionOutComponent, {
        disableClose: true,
        autoFocus: true,
        position: { top: '7%' },
      });
      sessionStorage.clear();
    }
  }
}
